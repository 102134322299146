import {useEffect, useState} from "react";

/**
 * Callback that is executed just once and only on client side rendering.
 */
export const useOnceOnClientSide = (callback: () => void) => {
    const [wasExecuted, setWasExecuted] = useState(false);

    useEffect(() => {
        if (!wasExecuted) {
            setWasExecuted(true);
            callback();
        }
    }, [wasExecuted]);
};
