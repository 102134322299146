/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require("./src/css/index.css");

const React = require("react");
const PageWrapper = require("./src/components/layout/page-wrapper").default;

exports.wrapPageElement = ({element, props}) => {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it

    return <PageWrapper {...props}>{element}</PageWrapper>;
};
