export const apiDocsSlug = (hash?: string) => {
    const base = "/api-docs";
    return hash ? `${base}#${hash}` : base;
};

export const gettingStartedSlug = (subPage = "grid-data-structure") => {
    const base = "/getting-started";
    return subPage ? `${base}/${subPage}` : base;
};

export const privacyPolicySlug = () => `/privacy-policy`;

export const imprintSlug = () => `/imprint`;
