exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-docs-tsx": () => import("./../../../src/pages/api-docs.tsx" /* webpackChunkName: "component---src-pages-api-docs-tsx" */),
  "component---src-pages-getting-started-grid-data-structure-tsx": () => import("./../../../src/pages/getting-started/grid-data-structure.tsx" /* webpackChunkName: "component---src-pages-getting-started-grid-data-structure-tsx" */),
  "component---src-pages-getting-started-grid-reducers-tsx": () => import("./../../../src/pages/getting-started/grid-reducers.tsx" /* webpackChunkName: "component---src-pages-getting-started-grid-reducers-tsx" */),
  "component---src-pages-getting-started-grid-selectors-tsx": () => import("./../../../src/pages/getting-started/grid-selectors.tsx" /* webpackChunkName: "component---src-pages-getting-started-grid-selectors-tsx" */),
  "component---src-pages-getting-started-grid-side-effects-tsx": () => import("./../../../src/pages/getting-started/grid-side-effects.tsx" /* webpackChunkName: "component---src-pages-getting-started-grid-side-effects-tsx" */),
  "component---src-pages-getting-started-grid-transformers-tsx": () => import("./../../../src/pages/getting-started/grid-transformers.tsx" /* webpackChunkName: "component---src-pages-getting-started-grid-transformers-tsx" */),
  "component---src-pages-getting-started-grid-walkers-tsx": () => import("./../../../src/pages/getting-started/grid-walkers.tsx" /* webpackChunkName: "component---src-pages-getting-started-grid-walkers-tsx" */),
  "component---src-pages-getting-started-searching-cell-values-in-a-grid-tsx": () => import("./../../../src/pages/getting-started/searching-cell-values-in-a-grid.tsx" /* webpackChunkName: "component---src-pages-getting-started-searching-cell-values-in-a-grid-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

