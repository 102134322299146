import React, {FC} from "react";

interface ExtLinkProps {
    href: string;
    className?: string;
    target?: string;
}

const ExtLink: FC<ExtLinkProps> = ({className, children, href, target = "_blank"}) => (
    <a href={href} rel="noopener noreferrer" target={target} className={className}>
        {children}
    </a>
);

export default ExtLink;
