import React, {FC, useEffect} from "react";
import {useMatomo} from "../../gdpr/hooks/use-matomo";
import {useOnceOnClientSide} from "../../hooks/use-once-on-client-side";
import {version} from "../../../package.json";
import {matomoPush} from "../../gdpr/utils/matomo";
import GdprCookieLayer from "../../gdpr/components/gdpr-cookie-layer";

const PageWrapper: FC = ({children}) => {
    const {requestsEnabled, cookiesEnabled} = useMatomo();

    useOnceOnClientSide(() => {
        // eslint-disable-next-line no-console
        console.log(`v${version}`);
    });

    useEffect(() => {
        if (!requestsEnabled) {
            matomoPush(["requireConsent"]);
        } else if (cookiesEnabled) {
            matomoPush(["enableJSErrorTracking"]);
        } else {
            matomoPush(["enableJSErrorTracking"]);
            matomoPush(["requireCookieConsent"]);
        }
    }, [cookiesEnabled, requestsEnabled]);

    return (
        <>
            {children}
            <GdprCookieLayer />
        </>
    );
};

export default PageWrapper;
