module.exports = [{
      plugin: require('../plugins/gatsby-source-typedoc-json/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4","siteUrl":"https://gridl.js.org","matomoUrl":"https://matomo.saschaklatt.dev","dev":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
