import {useCookies} from "react-cookie";
import {COOKIE_GDPR_CONSENT, YEAR_IN_SECONDS} from "../constants";
import {
    matomoAllowCookieTracking,
    matomoAllowRequestTracking,
    matomoDeclineCookieTracking,
    matomoDeclineRequestTracking,
} from "../utils/matomo";

interface GdprCookie {
    requests: boolean;
    cookies: boolean;
}

const cookieOptions = {
    path: "/",
    maxAge: YEAR_IN_SECONDS,
};

const encodeCookie = (decodedData: any) => {
    return btoa(JSON.stringify(decodedData));
};

const decodeCookie = (encodedData: string) => {
    return JSON.parse(atob(encodedData));
};

export const useMatomo = () => {
    const [cookies, setCookie] = useCookies([COOKIE_GDPR_CONSENT]);

    const defaultCookieValues: GdprCookie = {cookies: false, requests: true};
    const cookieDataRaw = cookies[COOKIE_GDPR_CONSENT];
    const cookieData: GdprCookie = cookieDataRaw ? decodeCookie(cookieDataRaw) : defaultCookieValues;

    const updateCookie = (cookie: GdprCookie) => {
        setCookie(COOKIE_GDPR_CONSENT, encodeCookie(cookie), cookieOptions);
    };

    const setCookiesEnabled = (flag: boolean) => {
        if (flag === cookieData.cookies) {
            return;
        }
        if (flag) {
            updateCookie({...cookieData, cookies: true, requests: true});
            matomoAllowRequestTracking();
            matomoAllowCookieTracking();
        } else {
            matomoDeclineCookieTracking();
            updateCookie({...cookieData, cookies: false});
        }
    };

    const setRequestsEnabled = (flag: boolean) => {
        if (flag === cookieData.requests) {
            return;
        }
        if (flag) {
            updateCookie({...cookieData, requests: true});
            matomoAllowRequestTracking();
        } else {
            matomoDeclineCookieTracking();
            matomoDeclineRequestTracking();
            updateCookie({...cookieData, requests: false, cookies: false});
        }
    };

    return {
        cookiesEnabled: cookieData.cookies,
        requestsEnabled: cookieData.requests,
        setCookiesEnabled,
        setRequestsEnabled,
    };
};
