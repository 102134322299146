import {useState, useEffect} from "react";
import {useMatomo} from "./use-matomo";

export const useCookieLayer = () => {
    const {cookiesEnabled, setCookiesEnabled} = useMatomo();
    const [isOpen, setIsOpen] = useState(false);

    const accept = () => {
        setCookiesEnabled(true);
        setIsOpen(false);
    };

    const decline = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        setIsOpen(!cookiesEnabled);
    }, [cookiesEnabled]);

    return {
        accept,
        decline,
        isOpen,
    };
};
