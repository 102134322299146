import React, {FC} from "react";
import {Link} from "gatsby";
import {Transition} from "@headlessui/react";
import {useCookieLayer} from "../hooks/use-cookie-layer";
import ExtLink from "../../components/ext-link";
import {matomoTrack} from "../utils/matomo";
import {ConsentChoice, cookieConsentCookieLayer} from "../tracking-events/cookie-consent";
import {privacyPolicySlug} from "../../helpers/slug-helper";

const GdprCookieLayer: FC = () => {
    const {isOpen, accept, decline} = useCookieLayer();

    const handleAcceptClick = () => {
        matomoTrack(cookieConsentCookieLayer(ConsentChoice.Accept));
        accept();
    };

    const handleDeclineClick = () => {
        matomoTrack(cookieConsentCookieLayer(ConsentChoice.Decline));
        decline();
    };

    return (
        <Transition
            appear={true}
            show={isOpen}
            className="fixed block bottom-0 z-overlay w-full bg-teal-dark text-white px-4 md:px-0"
            enter="transform transition ease-out duration-300 delay-1000"
            enterFrom="opacity-0 translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="transform transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-full"
        >
            <div className="container mx-auto my-4">
                <h3>{"Cookies!"}</h3>
                <p>
                    {"This site uses "}
                    <ExtLink href="https://matomo.org" className="text-white underline">
                        {"Matomo"}
                    </ExtLink>
                    {` to analyse traffic and help me to improve the gridl library and the user experience of this website. By accepting cookies, Matomo tracking cookies will be stored on your device for 12 months. You can change your cookie settings at any time and withdraw your consent or opt-out from tracking entirely on the `}
                    <Link to={privacyPolicySlug()} className="text-white underline">
                        {"privacy policy"}
                    </Link>
                    {" page."}
                </p>
            </div>
            <div className="container mx-auto my-4">
                <button className="btn-ghost mr-4" onClick={handleDeclineClick}>
                    {"Decline"}
                </button>
                <button className="btn-primary" onClick={handleAcceptClick}>
                    {"Accept cookies"}
                </button>
            </div>
        </Transition>
    );
};

export default GdprCookieLayer;
