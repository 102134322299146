import {MatomoTrackingEvent} from "../types";

export const getMatomo = () => (window as any)._paq;

export const matomoPush = (...args: any) => {
    getMatomo().push(...args);
};

export const matomoTrack = (trackingEvent: MatomoTrackingEvent) => {
    matomoPush(_trackingEventToArray(trackingEvent));
};

export const matomoAllowCookieTracking = () => {
    matomoPush(["setCookieConsentGiven"]);
};

export const matomoDeclineCookieTracking = () => {
    matomoPush(["forgetCookieConsentGiven"]);
};

export const matomoAllowRequestTracking = () => {
    matomoPush(["setConsentGiven"]);
};

export const matomoDeclineRequestTracking = () => {
    matomoPush(["forgetConsentGiven"]);
    matomoPush(["requireConsent"]);
};

const _trackingEventToArray = (event: MatomoTrackingEvent) => {
    const trackingArr = ["trackEvent", event.category, event.action];
    if (event.name && !event.value) {
        return [...trackingArr, event.name];
    }
    if (event.name && event.value) {
        return [...trackingArr, event.name, event.value];
    }
    if (!event.name && event.value) {
        return [...trackingArr, undefined, event.value];
    }
    return trackingArr;
};
