import {MatomoTrackingEvent} from "../types";

const cookieConsent = () => ({
    category: "Cookie Consent",
});

export enum ConsentChoice {
    Accept = "accept",
    Decline = "decline",
}

export const cookieConsentCookieLayer = (choice: ConsentChoice): MatomoTrackingEvent => ({
    ...cookieConsent(),
    action: "Click cookie layer",
    name: choice,
});

export const cookieConsentButton = (choice: ConsentChoice): MatomoTrackingEvent => ({
    ...cookieConsent(),
    action: "Click cookie button",
    name: choice,
});

export const requestConsentButton = (choice: ConsentChoice): MatomoTrackingEvent => ({
    ...cookieConsent(),
    action: "Click request button",
    name: choice,
});
